.head-title{
    width:100%;
    padding:6rem 0;
    position: relative;
    width: 100%;
    background-size: cover;
    color:#fff;
    background: linear-gradient(90deg, #009c00 0%, #00b0ff 100%);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1; 
      background: url('../img/head-titlebg.png') center center no-repeat;
      background-size:cover;
    }
    &.orange{
        background: linear-gradient(90deg, rgba(250,134,50,1) 0%, rgba(248,194,50,1) 100%);
    }
    &.orange-color{
      background:#F6911E;
      padding:10rem 0; 
      &:before{
        background:#F6911E;
        
      }
    }
    &.blue{
        background: linear-gradient(90deg, rgba(69,187,211,1) 0%, rgba(48,199,204,1) 100%);
    }
    &.lightblue{
        background: linear-gradient(90deg, rgba(119,152,249,1) 0%, rgba(0,176,255,1) 100%);
    }
    &.green{
        background: linear-gradient(90deg, rgba(0,156,0,1) 0%, rgba(0,176,255,1) 100%);
    }
    &.about{
        padding:6rem 0;
        background: linear-gradient(90deg, rgba(0,156,0,1) 0%, rgba(0,176,255,1) 100%);
        &:before{
            background: url('../img/aboutbg.png') center center no-repeat;
            opacity: .2; 
            background-size:cover;
        }
    }
    &.general-information{
      padding:6rem 0;
      background: linear-gradient(90deg, rgba(124,66,150,1) 0%, rgba(216,39,52,1) 100%);
      &:before{
          background: url('../img/aboutbg.png') center center no-repeat;
          opacity: .2; 
          background-size:cover;
      }
    }
  &.faq-page{
    padding:6rem 0;
    background: linear-gradient(90deg, rgba(119,152,249,1) 0%, rgba(0,176,255,1) 100%);
    &:before{
        background: url('../img/aboutbg.png') center center no-repeat;
        opacity: .2; 
        background-size:cover;
    }
  }
    &.contact{
      padding:6rem 0;
      background: linear-gradient(90deg, rgba(119,152,249,1) 0%, rgba(0,176,255,1) 100%);
      &:before{
          background: url('../img/contactbg.png') center center no-repeat;
          opacity: .2; 
          background-size:cover;
      }
    }
    &.partners{
      padding:6rem 0;
      background: linear-gradient(90deg, rgba(124,66,150,1) 0%, rgba(216,39,52,1) 100%);
      &:before{
          background: url('../img/partnersbg.png') center center no-repeat;
          opacity: .2; 
          background-size:cover;
      }
    }
    &.requestdemo{
      padding:6rem 0;
      background: linear-gradient(90deg, rgba(250,134,50,1) 0%, rgba(248,194,50,1) 100%);
      &:before{
          background: url('../img/requestdemobg.png') center center no-repeat;
          opacity: .2; 
          background-size:cover;
      }
    }
    
    &.references{
      padding:6rem 0;
      background: linear-gradient(90deg, rgba(255,153,102,1) 0%, rgba(255,94,98,1) 100%);
      &:before{
          background: url('../img/referencesbg.png') center center no-repeat;
          opacity: .2;
          background-size:cover; 
      }
    }
    h3{
        font-size:1.4em;
        font-weight:400;
    }
    p{
        margin:0;
       color:#fff;
       font-weight:400;
    }
}
.customer-review{
    background:url('../img/ReviewImg.png');
    background-size:cover;
    width:100%;
    padding:2rem 0 10rem 0;
    .avatar{
        img {
            width:120px;
            height:120px;
            box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.15);
            border-radius:100%;
            border:4px solid #fff;
        }
    }
    h3{
        color:#fff;
        font-size:2em;
        font-weight:400;
    }
    p{
        color:#fff;
        font-style:italic;
        font-weight:400;
        font-size:.9em;
    }
    .normal{
        font-style:normal;
    }
    
}
.customer-video{
    margin-top:-100px;
}

//Pricing
.pricing{
    h3{
        margin-top:3%;
        font-weight:400;
    }
    .select-time{
        background:rgba(247, 146, 30, 0.1);
        padding:.2rem;
        p{
            margin:0;
            font-weight:400;
            span{
                font-size:.8em;
                color:#F7921E;
            }
        }
    }
    .checkbox.checbox-switch {
        padding-left: 0;
        margin-top:15px;
        label {
          display: inline-block;
          position: relative;
          padding-left: 0;
        }
      }
      
      .checkbox-inline.checbox-switch {
        display: inline-block;
        position: relative;
        padding-left: 0;
      }
      
      .checkbox.checbox-switch label input, .checkbox-inline.checbox-switch input {
        display: none;
      }
      
      .checkbox.checbox-switch label span, .checkbox-inline.checbox-switch span {
        width: 60px;
        border-radius: 20px;
        height: 28px;
        background-color: #fff;
        border-color: #dbdbdb;
        box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      }
      
      .checkbox.checbox-switch label span:before, .checkbox-inline.checbox-switch span:before {
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #E5E5E5;
        content: " ";
        top: 2px;
        position: relative;
        left: -15px;
        transition: all 0.3s ease;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        
      }
      
      .checkbox.checbox-switch label > input:checked + span:before, .checkbox-inline.checbox-switch > input:checked + span:before {
        left: 16px; 
        background: #F7921E;
      }
      
      /* Switch Primary */
      
      .checkbox.checbox-switch.switch-primary label > input:checked + span, .checkbox-inline.checbox-switch.switch-primary > input:checked + span {
        background-color: #fff;
        border-color: #fff;
      
        /*box-shadow: rgb(0, 105, 217) 0px 0px 0px 8px inset;*/
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
      }
      
      .checkbox.checbox-switch.switch-primary label > input:checked:disabled + span, .checkbox-inline.checbox-switch.switch-primary > input:checked:disabled + span {
        background-color: #fff;
        border-color: #fff;
      
        /* box-shadow: rgb(109, 163, 221) 0px 0px 0px 8px inset;*/
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
      }
      .card{
        border-radius:.75rem;
        border:0;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
        margin-top:2rem;
        h4{
            color:#666666;
            font-size:1.2em;
            margin-top:-80px;
            @media (max-width: 991.98px) {
                font-size:1em;
            }
        }
        a{
            color:#B5B5B5;
            font-size:.975em;
            text-decoration:underline;
            @media (max-width: 991.98px) {
                font-size:.8em;
            }
        }
    }
    .list{
        list-style-type: none;
        text-align:left;
        padding:0;
    }
    p{
        span{
            color:#FA8732;
        }
    }
    .pd{
        padding-right:6rem;
        padding-left:6rem;
        @media (max-width: 991.98px) {
           padding-right:.5rem;
           padding-left:.5rem;
        }   
    }
    .orange{
        color:#FA8732;
        @media(min-width:1023px)and(max-width:1199px){
          font-size:.8rem !important;
          padding:0 2rem !important;
        }
        @media(min-width:768px)and(max-width:1022px){
          width:100%;
          font-size:.7rem !important;
          padding:0 .2rem !important;
        }
    }
    /* Vertical line */
.collapsible-link {
    text-decoration:none !important;
    font-size:1.1em;
    font-weight:400;
    color:inherit !important;
	&::before {
        content:url(../img/icons/accordion-open.svg) !important;
		width: 27px;
		height: 27px;
		position: absolute;
		right: -30px;
		display: block;
        transition: all 0.3s;
        
	}
	
}
.collapsible-link[aria-expanded='true'] {
	&::after {
		transform: rotate(90deg) translateX(-1px);
	}
	&::before {
		transform: rotate(180deg);
	}
}
.accordion{
  h6{
    width:94%;
  }
}

}
.table-striped {
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: #FEE5D2 !important;
            }
            font-size:.8em;
            font-weight:400;
        }
    }
    border:1px solid #FEE5D2;
    thead{
        th{
            border-bottom:4px solid #FA8732;
            border-top-color:#FEE5D2;
            font-weight:400;
            font-size:.9em;
        }
    }
    td{
        border-color:#FEE5D2;
    }
}
.accordion{
    .card{
        margin-top:1rem;
    }
}


//About
.about{
    .timeline {
        padding: 50px 0;
        position: relative;
      }
      
      .timeline-nodes {
        padding-bottom: 25px;
        position: relative;
      
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
      }
      
      .timeline {
        h3, p {
          padding: 5px 15px;
        }
      
        h3 {
          font-weight: 400;
          font-size:2em;
          margin-bottom:0px;
          padding:0;

        }
        .year{
            font-size:2em;
            font-weight:400;
            color:#F7921E;
        }
        p, time {
          font-size:300;
          font-size:1em;
          margin:0;
          padding:0;
        }
      
        &::before {
          content: "";
          display: block;
          position: absolute;
          border-radius:.25rem;
          top: 0;
          left: 50%;
          width: 0;
          border-left: 10px solid #F9BA6B;
          height: 100%;
          z-index: 1;
          transform: translateX(-50%);
        }
      }
    
      
      .timeline-nodes {
        &:nth-child(odd) {
          h3, p {
            text-align: right;
            
          }
      
          .timeline-date {
            text-align: left;
          }
        }
      
        &:nth-child(even) .timeline-date {
          text-align: right;
        }
      }
      
      .timeline-image {
        position: relative;
        z-index: 100;
      
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          border: 8px solid #F7921E;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 50%);
          background-color: #fff;
          z-index: 1;
        }
      
        img {
          position: relative;
          z-index: 100;
        }
      }
      
      /*small device style*/
      
      @media (max-width: 767px) {
        .timeline-nodes {
          &:nth-child(odd) {
            h3, p {
              text-align: left;
            }
          }
      
          &:nth-child(even) {
            flex-direction: row;
          }
        }
      
        .timeline {
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 4%;
            width: 0;
            border-left: 10px solid #F9BA6B;
            height: 100%;
            z-index: 1;
            transform: translateX(-50%);
          }
      
          h3 {
            font-size: 1.7rem;
          }
      
          p {
            font-size: 14px;
          }
        }
      
        .timeline-image {
          position: absolute;
          left: 0%;
          top: 60px;
      
          /*transform: translateX(-50%;);*/
        }
      
        .timeline-nodes {
         
      
          &:nth-child(even) {
            
      
            .timeline-date {
              text-align: left;
            }
          }
        }
      
        .timeline-image::before {
          width: 65px;
          height: 65px;
        }
      }
      
      /*extra small device style */
      @media (max-width: 575px) {
        .timeline::before {

          position: absolute;
          top: 0;
          left: 5%;
        }
      
        .timeline-image {
          position: absolute;
          left: -3%;
            
          &::before {
            width: 40px;
            height: 40px;
          }
        }
      }
}

//About-Team
.about-team{
    background:#FAFAFA;
    padding:5% 0;
    h3{
        font-size:2em;
        font-weight:400;
        span{
            color:#F6911E;
        }
    }
    img{
        width:200px;
        height:200px;
        border-radius:50%;
        margin-bottom:20px;
    }
    h3{
        font-size:1.6em;
        font-weight:300;
        margin:0;
    }
    h4{
        font-size:1.6em;
        font-weight:400;
        color:#F6911E;
        margin:0;
    }
    .team-desc{
        font-size:.95em;
        font-weight:300;
    }
}

//Integrations-PAge
.integrations-page{
  .box{
    border:1px solid #E5E5E5;
    border-bottom:0px;
    border-right:0px;
    line-height:150px;
    height:150px;
    width:100%;
    &:last-child{
      border-bottom:1px solid #E5E5E5;
      border-right:1px solid #E5E5E5;
    }
  }
}

.contact-pages{
  h3{
    font-size:1.2em;
    font-weight:400;
  }
  p{
    padding:.3rem 0;
    span{
      color:#FA8632
    }
  }
  .contact-maps{
    background:#FAFAFA;
    padding:.5rem 0;
    hr{
      border-top:5px solid #999999;
      clear:both;
      width:15%;
      border-radius:1rem;
      margin-bottom:0;
      margin-top:0;
  
    }
  }
  
}

//Partners-References
.partners-page,.references-page{
  margin-bottom:4rem;
  .partners-filter{
    background:#FAFAFA;
    width:100%;
    height:100%;
    padding:.75rem;
    border-radius:.5rem;
    
  }
  
  .card{
    height:350px;
    margin-bottom:1.5rem;
    .card-footer{
      background:#fff;
      border:none;
    }
    p{
      a{
        color:#FA8632;
      }
    }
    &.become{
      background:#FAFAFA;
    }
  }
  
  
}

//Login-Register
.login-register{
background:url('../img/loginbg.png') center no-repeat;
margin:auto;
width:100vw;
height:100vh;
background-color:#fff;
padding:5rem 0;
  .form-control{
    background:#FAFAFA !important;
    border-color:#DCDCDC !important;
    border-radius:.25rem !important;
    box-shadow:none !important;
    height:3rem;
    line-height:3rem;
    border-left:0;
    color:#000;
    &::placeholder{
      color:#999;
    }
  }
  .input-group-text{
    background:#FAFAFA !important;
    border-color:#DCDCDC !important;
    border-right:0;
  }
  .card{
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
    border:0;
  }
}
//404
.errorpages{
  background:url('../img/404bg.png') no-repeat;
  background-size:cover;
  width:100vw;
  height:100vh;
  padding:3rem 0;
  color:#999999;
  p{
    margin:0;
    padding:0;
    &.big{
      font-size:45px;
      font-weight:700;
      @media (max-width: 991.98px) {
        font-size:45px;
      }
      @media (max-width: 575.98px) {
        font-size:28px;
      }
    }
    &.small{
      font-size:28px;
      @media (max-width: 991.98px) {
        font-size:28px;
      }
      @media (max-width: 575.98px) {
        font-size:20px;
      }
    }
    &.huge{
      font-size:270px;
      line-height:250px;
      font-weight:700;
      @media (max-width: 991.98px) {
        font-size:270px;
        line-height:250px;
      }
      @media (max-width: 575.98px) {
        font-size:140px;
        line-height:120px;
      }
    }
  }
}

//General-Information
.general-information{
  .menu{
    background:#fafafa;
    width:100%;
    height:100%;
    padding:1rem;
  }
  .nav-link{
    font-size:.85em;
    color:#595959;
    &.active{
      background:#ccc;
      color:#595959;
      font-weight:700;
    } 
  }
  .sublist{
    background:#fafafa;
    padding:.5rem;
    .nav-link{
      padding:.1rem 1rem;
    }
  }
}

//Faq Page
.faq-page{
  #custom-search-input {
    margin:0;
    margin-top: 10px;
    padding: 0;
    font-size:1.2em;
    color:#595959;
    &:placeholder{
      color:#999;
    }
}
 
#custom-search-input .search-query {
    width:100%;
    padding: .5rem 1rem;
    margin-bottom: 0;
    border-radius: 1.5rem;
    height:3.2rem;
  
}
 
#custom-search-input button {
    border: 0;
    background: none;
    padding: .5rem 1rem;
    margin-top: 2px;
    position: absolute;
    right:0;
    margin-bottom: 0;
    border-radius: 3px;
    color:#D9230F;
    cursor: unset;
    z-index: 2;
}
 
.search-query:focus{
    z-index: 0;   
}
h5{
  color:#198FD0;
  font-weight:700;
}
.flex-column{
  .nav-link{
    padding:.2rem 0rem;
    color:#595959;
  }
}

}

.ref-page{
  width:1140px;
  margin-top:-80px; 
  background-color:white; 
  position:absolute; 
  border-radius:.25rem;
  box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.15);
  padding:0 .5rem;
  @media (min-width: 992px)and(max-width: 1025px){
    max-width:960px;
    margin-top:-50px;
  }
}

.insurance-page{
  .tourism-sector{
    background: rgb(250,134,50);
    background: linear-gradient(90deg, rgba(250,134,50,1) 0%, rgba(248,194,50,1) 100%);
    width:100%;
    padding:2rem 0;
  }
}
.section-lines{
  margin:0 auto !important;  

}
.spacey-1{
  @media(min-width:1450px)and(max-width: 1920px){
    margin-top:30%;
  }
  @media (max-width: 1440px){
    margin-top:25%;
  }
}
