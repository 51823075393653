//Header
.header-default {
     width: 100%;
     box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
     background-color: #fff;
     position: fixed;
     z-index: 99;
     @media (max-width: 991.98px) {
          position: relative;
     }
     .bsnav {
          padding-top: 5px;
          padding-bottom: 5px;
          .nav-link {
               font-size: 0.9em !important;
               color: #595959 !important;
               font-weight: 400 !important;
               
          }
          .orangetext {
               color: #fa8632 !important;
               width: 100%;
          }
          .nav-item {
               &.dropdown {
                    .navbar-nav {
                         min-width: 250px;
                         padding: 0.5rem;
                         box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
                         border-radius: 0.15rem;

                         &.lg {
                              min-width: 340px;
                         }
                         .nav-link{
                              &:hover{
                                   background:#fafafa;
                              }
                         }
                    }
               }
          }
     }
     &.relative {
          position: relative;
     }
     .nav-item.active .caret,
     .nav-item:hover .caret {
          border-top-color: #595959 !important;
     }
}
.lng {
     border: 1px solid #b5b5b5;
     border-radius: 5px;
     padding: 0.25em;
     color: #b5b5b5 !important;
     font-size: 0.975em;
     .dropdown-item{
          color: #b5b5b5 !important;
     }
}
.navbar-nav > * .dropdown:hover > .dropdown-menu {
     display: block;
}
.swiper-container-references > * img {
     filter: grayscale(100%);
}
.dropdown-h3 {
     margin: 0;
     font-size: 0.9em;
     font-weight: 600;
     margin-left: 0.5rem;
     color: #666;
}
.navbar-nav .nav-item.active>.nav-link, .navbar-nav .nav-item:active:focus>.nav-link, .navbar-nav .nav-item:active>.nav-link, .navbar-nav .nav-item:focus>.nav-link, .navbar-nav .nav-item:hover>.nav-link{
     color: #fa8632 !important;   
}