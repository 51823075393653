//Main Slider
.main-slider{
  .m-slide{
    @media (max-width: 991.98px) {
      min-height:500px;
      .green{
        margin-right:2rem;
      }
      .white{
        margin-top:0;
      }
    }
    @media (max-width: 575.98px) {
      min-height:600px;
      .green{
        margin-right:0;
      }
      .white{
        margin-top:1rem;
      }
     }
  }
  
    .carousel-caption{
      top:20%;
      max-width:1140px;
      margin:auto;
      text-align:left;
      
      @media (max-width: 991.98px) {
        top:5%;
        max-width:100%;
        left:0%;
        right:0%;
      }
      h3{
        font-weight:300;
        font-size:49px;
        color:#656565;
        @media (max-width: 991.98px) {
          font-size:32px;
        }
        span{
          color:#FA8732;
        }
      }
      p{
        font-weight:300;
        font-size:23px;
        color:#656565;
        @media (max-width: 991.98px) {
          font-size:15px;
        }
        span{
          color:#FA8732;
        }
      }
    }
    @media (max-width: 1600px) {
      img{
        &.float-right{
          width:51%;
         
        }
     
      }
    }
    @media (min-width: 992px)and(max-width: 1025px){
      .carousel-inner{
        min-height:400px;
      }
      .carousel-caption{
        h3{
          font-size:36px;
        }
        p{
          font-size:16px;
        }
      }
    }
}

.swiper-container-references{
  height:150px;
  line-height:150px;
  @media (max-width: 991.98px) {
    height:100px;
    line-height:100px;
  }
  @media (max-width: 575.98px) {
    height:70px;
    line-height:70px;
  }

}
.sliderplaybtn{
  position:absolute;
  right:35%;
  @media (max-width: 1600px) {
    right:5%;
    bottom:12%;
    img{
      width:55%
    }
  }
  @media(max-width:1440px){
    right:6%;
    bottom:16%;
    img{
      width:55%
    }
  }
}