//General
html,
body {
    font-family: $primary-font;
    font-size: 1em;
    color: #656565;
    background: #fff;
    max-width: 100%;
    max-height: 100%;
    scroll-behavior: smooth;
    @media (max-width: 991.98px) {
        overflow-x: hidden;
    }
}
.btn {
    height: 3rem;
    padding: 0 2.5rem;
    line-height: 3rem;
    border-radius: 2.5rem !important;
    background-size: 100% auto !important;
    font-size: 0.9em;
    border: 0;
    outline: 0 !important;
    @media (max-width: 991.98px) {
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.8em;
    }
    &.blue {
        color: #fff;
        background: #45bbd3;
        font-weight: 700;
        &:hover {
            background: #4dbed5;
        }
    }
    &.green {
        background-image: linear-gradient(to right, rgb(0, 156, 0) 0%, rgba(0, 176, 255, 1) 100%);
        color: #fff;
        font-weight: 700;

        &:hover {
            background-image: linear-gradient(to right, rgba(0, 156, 101, 1) 0%, rgba(0, 159, 255, 1) 100%);
        }
        &.rectangle {
            background: #009c00;
            font-weight: 400;
            border-radius: 0.25rem !important;
            font-size: 1em;
            &:hover {
                background: #09ad09;
            }
            &.gradient {
                background-image: linear-gradient(to right, rgba(0, 156, 0, 1) 0%, rgba(0, 176, 255, 1) 100%);

                font-size: 700;
                &:hover {
                    background-image: linear-gradient(to right, rgba(0, 156, 101, 1) 0%, rgba(0, 159, 255, 1) 100%);
                }
            }
        }
    }
    &.white {
        background: rgb(255, 255, 255);
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.19);
        color: #adadad;
        font-weight: 700;
        padding: 0 0.25rem 0 2.5rem;
        @media (max-width: 991.98px) {
            padding: 0 0.25rem 0 1rem;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &.signup {
        color: #009c00;
        border: 1px solid #009c00;
        background: transparent;
        height: 2.1rem;
        line-height: 2.1rem;
        font-size: 0.8em;
        font-weight: 700;
        padding: 0 2.5rem;
        &:hover {
            background: #009c00;
            color: #fff;
        }
    }
    &.buynow {
        color: #fff;
        background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
        height: 2.1rem;
        line-height: 2.1rem;
        font-size: 0.8em;
        font-weight: 700;
        padding: 0 2rem;
        &:hover {
            background-image: linear-gradient(to right, rgba(250, 172, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
        }
    }
    &.orange {
        background: #f6911e;
        color: #fff;
        padding: 0 3rem;
        font-weight: 700;
        &:hover {
            background: #fc9724;
        }
        &.gradient {
            background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
            margin-top:10px;
            &:hover {
                background-image: linear-gradient(to right, rgba(250, 172, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
            }
        }
        &.rectangle {
            font-weight: 400;
            border-radius: 0.25rem !important;
            font-size: 1em;
            &.gradient {
                background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);

                font-size: 700;
                &:hover {
                    background-image: linear-gradient(to right, rgba(250, 172, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
                }
            }
        }
    }
    &.contact-btn {
        border: 2px solid #f6911e;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 1rem !important;
        font-weight: 700 !important;
        font-size: 0.85em !important;
        color: #f6911e;
        &:hover {
            background-color: #f6911e;
            color: #ffffff;
        }
    }
}

.section-title {
    font-size: 42px;
    font-weight: 400;
    @media (max-width: 991.98px) {
        font-size: 32px;
    }
    &.references {
        font-size: 36px;
        text-align: right;
        @media (max-width: 991.98px) {
            font-size: 28px;
        }
    }
    span {
        color: #fba047;
    }
    &.small {
        font-size: 32px;
        font-weight: 400;
    }
    &.login {
        font-size: 24px;
        font-weight: 300;
    }
}
