//Media
.media {
    &.custom {
        @media (max-width: 991.98px) {
            text-align: center;
        }
        &.ltr {
            @media (max-width: 991.98px) {
                margin: 2rem 0;
                .last {
                    order: -1;
                }
            }
        }
        margin-top: 3%;
        h3 {
            font-size: 1.3em;
            font-weight: 700;
            @media (max-width: 991.98px) {
                font-size: 0.9em;
            }
        }
        h4 {
            font-size: 2.62em;
            font-weight: 400;
            @media (max-width: 991.98px) {
                font-size: 1.4em;
            }
        }
        h5 {
            font-size: 2em;
            font-weight: 400;
            @media (max-width: 991.98px) {
                font-size: 1.1em;
            }
        }
        p {
            font-size: 0.9em;
            @media (max-width: 991.98px) {
                font-size: 0.8em;
            }
        }
        .down-text {
            color: #b5b5b5;
            text-decoration: underline;
            font-weight: 700;
            @media (max-width: 991.98px) {
                font-size: 0.85em;
            }
        }
        .btn {
            font-size: 0.9em;
            font-weight: 700;
            margin-top: 1.5rem;
            @media (max-width: 991.98px) {
                font-size: 0.8em;
                margin-top: 0.8em;
            }
        }
        .orange {
            h3 {
                color: #fa8732;
            }
            .btn {
                color: #fff;
                background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
                &:hover{
                    background-image: linear-gradient(to right, rgba(250,172,50,1) 0%, rgba(248,194,50,1) 100%);

                }
            }
        }
        .blue {
            h3 {
                color: #45bcd3;
            }
            .btn {
                color: #fff;
                background: #45bbd3;
                &:hover{
                    background:#4dbed5;
                }
            }
        }
        .green {
            h3 {
                color: #00a039;
            }
            .btn {
                color: #fff;
                background-image: linear-gradient(to right, rgba(0, 156, 0, 1) 0%, rgba(0, 176, 255, 1) 100%);
                &:hover{
                    background-image: linear-gradient(to right, rgba(0,156,101,1) 0%, rgba(0,159,255,1) 100%);
                }
            }
        }
        .default {
            .btn {
                font-weight: 300;
                padding: 0;
                &.white {
                    color: #fa8d32;
                    padding: 0 2.5rem;
                    font-weight: 700;
                }
            }
        }
    }
}

//Integrations
.integrations {
    margin-top: 7%;
    @media (max-width: 991.98px) {
        p {
            font-size: 0.85em;
        }
    }
}

//Testimonials
.testimonials {
    margin-top: 7%;
    @media (min-width: 1366px) {
    .swiper-slide {
        &:nth-child(1) {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index:9999;
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(248,194,50,0) 100%);

            }
        }
        &:nth-child(3) {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index:9999;
                background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(248,194,50,0) 100%);

            }
        }
    }
}
    .card {
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 0.5rem;
        padding: 1.5rem 6rem;
        @media (max-width: 991.98px) {
            padding: 1.5rem;
        }
        @media (min-width: 992px)and(max-width: 1441px){
            padding:1.5rem;
        }
        .avatar {
            img {
                width: 110px;
                height: 110px;
                box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
                border-radius: 100%;
                border: 4px solid #fff;
            }
        }
        p {
            font-size: 0.9em;
        }
        hr {
            border-top: 5px solid #d8d8d8;
            width: 100px;
        }
        .curly {
            img {
                width: 162px;
                height: auto;
                position: absolute;
                left: 10px;
            }
        }
    }
    @media (max-width: 991.98px) {
        padding: 0;
    }
}

//App
.app {
    margin-top: 5%;
    .btn {
        &.down {
            padding: 0.25rem 1rem !important;
            width: 220px;
            text-align: center;
            line-height: 1.3em;
            color: #fff;
            background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
            box-shadow: 0px 15px 17px 0px rgba(247, 147, 29, 0.45);
            border-radius: 0;
            @media (max-width: 991.98px) {
                width: 150px;
                line-height: 1.2em;
                img {
                    width: 22%;
                    height: auto;
                }
            }
            &:hover{
                background-image: linear-gradient(to right, rgba(250,172,50,1) 0%, rgba(248,194,50,1) 100%);
            }
            p {
                &.small {
                    text-align: left;
                    @media (max-width: 991.98px) {
                        font-size: 0.7em;
                    }
                }
                &.big {
                    font-size: 1.5em;
                    font-weight: 600;
                    @media (max-width: 991.98px) {
                        font-size: 0.9em;
                    }
                }
            }
        }
    }
}

//Why
.why {
    margin-top: 5%;
    h3 {
        span {
            color: #fa8c32;
        }
    }
    .sub {
        font-weight: 300;
        font-size: 1.2em;
    }
}

//See
.see {
    background: url("../img/see-bg.png") no-repeat;
    background-size: cover;
    width: 100%;
    padding: 3rem 0;
    color: #fff;
    margin-top: 5%;
    .icons {
        height: 86px;
        line-height: 86px;
    }
    h3 {
        color: #fff;
        font-weight: 400;
    }
    p {
        font-size: 1.4em;
        &.head {
            font-size: 2.6em;
        }
    }
}

//Plans
.plans {
    background: url("../img/plan-bg.png") no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 500px;
    @media (max-width: 991.98px) {
        min-height: 400px;
    }
}
.packs {
    margin-top: -20%;
    @media (max-width: 991.98px) {
        margin-top: -50%;
    }
    @media (min-width: 992px)and(max-width: 1025px){
        margin-top:-45%;
    }
    @media (max-width: 575.98px) {
        margin-top: -90%;
        min-height: 250px;
    }
    @media(min-width:500px)and(max-width:575px){
        margin-top:-65%;
    }
    .nav-pills {
        padding-left: 6rem;
        padding-right: 6rem;
        @media (max-width: 991.98px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        .nav-item {
            padding-left: 2rem;
            padding-right: 2rem;
            @media (max-width: 991.98px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .nav-link {
            color: #fff;
            font-size: 1.1em;
            @media (max-width: 991.98px) {
                font-size: 0.9em;
            }
            &.active {
                background: #fff;
                color: #fa8932;
                border-radius: 1.6rem;
                padding-right: 2rem;
                padding-left: 2rem;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
            }
        }
    }
    .card {
        border-radius: 0.75rem;
        border: 0;
        box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
        margin-top: 2rem;
        h4 {
            color: #666666;
            font-size: 1.2em;
            margin-top: -80px;
            @media (max-width: 991.98px) {
                font-size: 1em;
            }
        }
        a {
            color: #b5b5b5;
            font-size: 0.975em;
            text-decoration: underline;
            @media (max-width: 991.98px) {
                font-size: 0.8em;
            }
        }
    }
    .list {
        list-style-type: none;
        text-align: left;
        padding: 0;
    }
    p {
        span {
            color: #fa8732;
        }
    }
    .pd {
        padding-right: 6rem;
        padding-left: 6rem;
        @media (max-width: 991.98px) {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
}
.pricebox {
    border-radius: 0.6rem !important;
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
    min-height: 600px;
    .card-footer {
        background: #fff;
        border: 0;
    }
    .head {
        margin-top: -60px;
        margin-left: -30px;
    }
    h3 {
        font-size: 1.4em;
        font-weight: 700;
        margin: 0;
    }
    .list {
        li {
            font-size: 0.82em;
            margin: 1rem 0;
        }
    }
    .card-footer {
        border: 0;
        span {
            font-size: 0.975em;
            color: #adadad;
            font-weight: 700;
        }
        p {
            color: #fa8832;
            font-weight: 700;
            font-size: 1.1em;
            span {
                font-size: 2.5em;
                color: #fa8832;
            }
        }
        .small {
            font-size: 0.6em;
            font-weight: 700;
            margin-bottom: 0.8rem;
        }
        .orange {
            color: #fff;
            background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
            text-decoration: none;
            &:hover{
                background-image: linear-gradient(to right, rgba(250,172,50,1) 0%, rgba(248,194,50,1) 100%);
            }
        }
    }
}

//Demo
.demo {
    margin-top: 5%;
    background: #45bbd3;
    width: 100%;
    color: #fff;
    padding: 4rem 0;
    h3 {
        font-size: 3em;
        font-weight: 400;
    }
    .white {
        padding: 0 2rem 0 2rem;
        box-shadow: none;
        height: 2.5rem;
        line-height: 2.5rem;
        color: #45bbd3;
    }
}

//Service-Banner
.service-banner {
    background: #333a54;
    color: #fff;
    width: 100%;
    .banner-text {
        margin-left: 38%;
        @media (max-width: 991.98px) {
            margin-left: 4%;
        }
        h3 {
            color: #fff;
            font-weight: 400;
            @media (max-width: 991.98px) {
                font-size: 24px;
            }
            @media (min-width: 992px)and(max-width: 1025px){
                font-size:26px;
            }
            span {
                color: #fa8732;
            }
        }
        .orange {
            color: #fff;
            background-image: linear-gradient(to right, rgba(250, 134, 50, 1) 0%, rgba(248, 194, 50, 1) 100%);
            &:hover{
                background-image: linear-gradient(to right, rgba(250,172,50,1) 0%, rgba(248,194,50,1) 100%);
            }
        }
        p {
            font-size: 0.8em;
        }
    }
}

//Map
.map {
    background: #f9fafc !important;
    padding: 3rem 0;
    width: 100%;
    position: relative;
    @media (max-width: 991.98px) {
        padding: 1rem 0;
    }
    .box {
        width: 8%;
        height: 8%;
        background-image: url("../img/icons/pin-green.svg");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        z-index: 9;
        &.orange {
            background-image: url("../img/icons/pin-orange.svg");
        }
    }

    .box:hover > .pin-text {
        display: block;
    }

    .pin-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 75%;
        white-space: nowrap;
        display: none;

        h4 {
            font-size: 0.95em;
            font-weight: 700;
            margin: 0;
            padding: 0;
            @media (max-width: 991.98px) {
                font-size: 0.8em;
            }
        }
        p {
            font-size: 0.95em;
            font-weight: 300;
            margin: 0;
            padding: 0;
            @media (max-width: 991.98px) {
                font-size: 0.8em;
            }
        }
        .card {
            border-radius: 0.7em;
            border: none;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
        }
        .white {
            height: 2.5rem;
            line-height: 2.5rem;
            padding: 0 0 0 1rem;
            @media (max-width: 991.98px) {
                height: 1rem;
                line-height: 1rem;
                padding: 0 0 0 0.5rem;
            }
        }
    }
}

//Index-Contact
.index-contact {
    margin-top: 5%;
    .green {
        padding: 0 0.6rem;
        border-radius: 0.25rem !important;
        background: #009c00;
        height: 2.5rem;
        line-height: 2.5rem;
    }
}

.form-control {
    color: #666666;
    font-size: 1em;
    font-weight: 400;
    border-color: #d7d7d7;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    &::placeholder {
        color: #cccccc;
    }
    &:focus {
        border-color: #fa8732;
        outline: 0;
        box-shadow: none;
    }
}
.form-group {
    margin-bottom: 1.4rem;
}
.form-check-label {
    font-size: 0.9em;
    a {
        color: #ed8532;
        text-decoration: underline;
    }
}

//Media-Sample
.media-sample {
    .light {
        font-weight: 300 !important;
    }
}

.bg-page {
    &.left {
        top: 17%;
        left: 0;
        position: absolute;
    }
    &.right {
        right: 0;
        position: absolute;
        overflow: hidden;
    }
    &.center {
        position: absolute;
        z-index: 99;
    }
}

.home-map{
    @media (min-width: 992px)and(max-width: 1025px){
        height:500px;
    }
    @media (min-width: 1025px)and(max-width: 1920px){
        height:800px;
    }
}
#more-txt {
  
    #more-collapse {
      &.collapse:not(.show) {
        display: block;
        height: 4rem;
        overflow: hidden;
      }
  
      &.collapsing {
        height: 4rem;
      }
    }
  
    a {
        font-size:.9em;
        &:hover{
            text-decoration:none;
        }
      &.collapsed::after {
        content: 'Read More';
        color:#FA8A32;
      }
  
      &:not(.collapsed)::after {
        content: 'Read Less';
        color:#FA8A32;
      }
    }
  }
  .read_more_text {
    max-height: 400px;
}
a {
    &.video{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.play_button {
    display: block;
    margin: auto;
    outline: none;
    position: absolute;
}
.txt-hh{
    height:90px;
}