//Footer
footer{
    background:#292A39;
    width:100%;
    color:#fff;
    padding:2rem 0;
    h5{
        font-size:1.05em;
        font-weight:700;
        padding:.8rem 0;
        margin:0;
    }
    .social-links{
        li{
            float:left;
            margin-right:.5rem;
        }
    }
    .dropdown{
        .btn{
            border-radius:.25rem !important;
            height:2rem;
            line-height:2rem;
            padding:0 1rem;
            background:#545561;
        }
    }
    .input-group{
        .form-control{
            height:2.5rem;
            line-height:2.5rem;
        }
        .btn{
            
            height:2.5rem;
            line-height:2.5rem;
            border-radius:.25rem !important;
            padding:0 1rem;
            color:#fff;
            background: rgb(250,134,50);
            background: linear-gradient(90deg, rgba(250,134,50,1) 0%, rgba(248,194,50,1) 100%);
        }
    }
    .footer-links{
        li{
            margin-bottom:.4rem;
            a{
                color:#fff;
                font-size:.9em;
            }
        }
    }
}
.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    background:#F6911E;
    z-index:999;
    padding:0 .9rem 1rem .9rem;
    border:0;
    border-radius:.25rem !important;
    &:hover, &:active, &:focus{
        background:#e5841d;
    }
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: white !important;
    background-color: #e5841d !important;
    border:0;
    box-shadow:none !important;
    outline:none !important
    
  }
